/* Header */
.header {
  background-color: var(--calcite-ui-background);
}

.header {
  height: 56px;
  padding: 10px;
  display: flex;
  align-items: center;
 
}

.header-title {
  font-size: 24px;
  flex: 1;
}

calcite-shell {
  background-color: #d5dfc7;
}

calcite-action-bar {
  border-top-width: 0px;
}

calcite-popover {
  --calcite-ui-text-1: #f1f8e8;
}

calcite-navigation {
  font-family: "Greycliff CF Extra", sans-serif;
  --calcite-navigation-width: 88%;
}

calcite-navigation-logo {
  --calcite-font-size-0: 22px;
  --calcite-ui-text-1: #285c4d;
  --calcite-ui-brand-hover: #37bd28;

}
calcite-stepper {
  --calcite-ui-text-1: #49b34e;
  --calcite-ui-text-2: #49b34e;
  --calcite-ui-text-3: #49b34e;
  --calcite-ui-brand: #498a45;
  --calcite-ui-border-1: #285c4d;
  margin: 30px 100px 120px 120px;
  padding: 3px 3px 3px 3px;
  background-color: #285c4d;
  font-family: "Greycliff CF Extra", sans-serif;
}


calcite-button {
  text-align: left;
  --calcite-ui-brand: #285c4d;
  --calcite-ui-brand-hover: #284107;
}

calcite-notice {
  --calcite-ui-foreground-1: #f1f8e8;
  --calcite-ui-text-1: #285c4d;
  --calcite-ui-brand: #285c4d;
  font-family: "Greycliff CF Extra", sans-serif;
}

calcite-navigation-user {
  --calcite-ui-brand: #285c4d;
}


.filter-icon-color {
  --calcite-ui-icon-color: aqua;
}

.esri-ui-bottom-left {
  height: 100%;
  width: 100%;
}

.esri-ui-bottom-left .esri-component {
  height: 50%;
}

.esri-layer-list {
  background-color: #242424;
  border: 1px solid rgba(173, 173, 173, .3)
}

.esri-layer-list__item {
  border-bottom: none;
}

arcgis-filter calcite-tip {
  padding: 10px
}

.user-profile-window {
  --calcite-panel-footer-padding: 0;
}

.remove-filter.sc-arcgis-filter {
  background-color: #242424;
}

.match-expression-msg {
  background-color: #242424 !important;
}

.tbl-widget-container {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  right: 10px;
  height: 50%;
  width: 60%;
}

/* overrides to style DebounceInput like CalciteInput  */
.lac-debounced-search-wrapper {
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  position: relative;
  align-items: center;
  font-size: 12px !important;
}

.lac-debounced-search-element-wrapper {
  display: inline-flex;
  flex: 1 1 0%;
  position: relative;
  order: 3;
  align-items: center;
}

.lac-debounced-search {
  text-align: start;
  height: 24px;
  padding-inline-start: 2rem !important;
  padding: 0.5rem;
  /* font-size: var(--calcite-font-size-1); */
  font-size: 12px;
  line-height: 1rem;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  background-color: var(--calcite-ui-foreground-1);
  border-color: var(--calcite-ui-border-input);
  color: var(--calcite-ui-text-1);
}

.lac-debounced-search-no-icon {
  padding-inline-start: 0.5rem !important;
}

.lac-debounced-search:focus {
  outline: 2px solid var(--calcite-ui-brand);
  outline-offset: -2px;
  border-color: var(--calcite-ui-brand);
  color: var(--calcite-ui-text-1);
}

.lac-debounced-search-icon {
  inset-inline-start: 0.5rem;
  position: absolute;
  z-index: 10;
  pointer-events: none;
  display: block;
  transition-property: all;
  transition-duration: var(--calcite-animation-timing);
  transition-delay: 0s;
}